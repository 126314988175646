<script>


import {
    required,
    helpers
  } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { mapActions } from "vuex";

//import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
/*
import formAnagraficaCliente from "@/components/widgets/formAnagraficaCliente";
import projectManagerPV from "@/components/widgets/projectManagerPV";

import documentManager from "@/components/widgets/documentManager";
import surveyManager from "@/components/widgets/surveyManager";
import accountingManager from "@/components/widgets/accountingManager";
*/

//import CKEditor from "@ckeditor/ckeditor5-vue";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import gestioneTeamDiLavoro from "@/components/widgets/worksites/gestioneTeamDiLavoro";
import gestioneMezziCantiere from "@/components/widgets/worksites/gestioneMezziCantiere";
import createReport from "@/components/widgets/tasks/createReport";

//import pianificazioneSubTask from "@/components/widgets/worksites/pianificazioneSubTask";
//import userSearchModelWS from "@/components/widgets/worksites/userSearchModelWS";


import appConfig from "../../../../app.config";
import moment from 'moment'
import serverConfig from '@/helpers/config';

import Swal from "sweetalert2";
import axios from 'axios';

  
  export default {
    name:'schedaAttivitaCompleta',
    props: [
      'projectID',
      'ID',
    ],
    setup() {     
      let tk = 'Bearer '+localStorage.getItem('tk')

      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Dettaglio gestione attività",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        showAdvancedProgect:true,
        data:{},
        worksite:{},
        project:{},
        activeTab:'Localizzazione cantiere',
        minDate:moment().format('YYYY-MM-DD hh:mm'),
        maxDate:new Date().getFullYear().toString() + '-12-31 23:59',
        labelLocation:'Localizzazione Cantiere: ',
      };
    },
    components: {
     // pianificazioneSubTask,
      gestioneTeamDiLavoro,
      gestioneMezziCantiere,
      createReport,
    },
    validations: {
      item: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
    mounted() {
      this.getItem()
      if (this.$route.name=='task-detail'){
        this.labelLocation = 'Localizzazione Installazione: '
      }
    },
  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    convertTmpToDate(val){
        return moment.unix(val).format('LLL')
    },
    convertDateToLocal(date){
      moment.locale('it')
      return moment(date).format('LLLL')
    },
    checkObjEl(obj){
      if(obj){
        return Object.keys(obj).length
      } else {
        return 0
      }
    },
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },
    setTitle(par){
      this.activeTab = par
    },
    
    getItem() {
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}tasks/${this.ID}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data) {
            this.data = response.data[0]
            if(this.data.worksites_list) {
              this.worksite = response.data[0].worksites_list[0]
            }
            if(this.data.projects_list) {
              this.project = response.data[0].projects_list[0] 
            }
            if (this.data.status=='assigned'){
              this.data.status = 'init'
            }          
                    
          }        
        })
    },
    init(){
      this.axiosInterceptor()
      axios.post(`${this.UrlServer}worksites/register`, this.worksite, {headers: { authorization:this.tk}}).then((response) => {
        if(response.data) {
          this.getItem()
          Swal.fire( this.$t("t-msg-worksite-tab-created-title") , this.$t("t-msg-worksite-tab-created-content"), "success");
        }
      })
    },
    setEndDate(){
        /*
        if (this.data.worksite.startStr){
            this.minDate = this.data.worksite.startStr
            this.maxDate = this.data.worksite.startStr
        }
        alert( moment().format('YYYY-MM-DD').add(30, 'days'))
        */
        this.update()
    },
    updateProgress(){
      if(this.data.progress>100){
        this.data.progress = 100
      }
      this.update()
    },
    update(){
     
     this.axiosInterceptor()
     
     this.data.dateString = this.data.startStr
     this.data.dateTmp = moment(this.data.startStr).unix()
     this.data.year = moment().format('YYYY')
     this.data.month = this.data.dateString.split('-')[1]
     this.data.day =  this.data.dateString.split('-')[2]
     this.data.start = moment(this.data.startStr).valueOf()
     this.data.start_tmp = moment(this.data.startStr).unix()
    
     this.data.end = moment(this.data.endStr).valueOf()
     this.data.end_tmp = moment(this.data.endStr).unix()

     axios.put(`${this.UrlServer}tasks/${this.data._id}`, this.data, {headers: { authorization:this.tk}})
     .then(() => {
         //this.getItem()
        // Swal.fire( this.$t("t-msg-updated") , this.$t("t-msg-updated-content"), "success");
     })
   },
   updateParent() {
      this.getItem()
    }
  },
  beforeMount() {

    },
  };
</script>

<template>
      <div class="row">
      <div class="col-lg-12">
       
        <div class="card background-btn-blue"  v-if="data.task_type =='task-cantiere'">
            <div class="text-center"><h3>Attività giornaliera di cantiere</h3></div>
        </div>
        <div class="card background-btn-orangeRed"  v-if="data.task_type =='task-assistenza'">
            <div class="text-center"><h3>Servizio di Assistenza (Ticket)</h3></div>
        </div>

      </div>
    </div>
      <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <div class="flex-grow-1">
                <div class="row">

                  <div class="col-lg-12 col-md-8">
                    <div class="input-group ">
                      <div class="input-group-prepend">
                        <template v-if="data">
                            <template v-if="data.assignedTo">
                              <template v-if="checkObjEl(data.assignedTo)==0">
                                <template v-if="$route.name=='worksite-detail-task'">
                                  <b-alert show variant="danger" role="alert">
                                  <strong>Attenzione:</strong> E' indispensabile assegnare la lavorazione ad un <strong>Team di lavoro</strong>, e ad un <strong>'team leader'</strong> o  <strong>'caposquadra'</strong> all'interno di questo.
                                  </b-alert>
                                </template>
                                <template v-if="$route.name=='support-detail-task'">
                                  <b-alert show variant="primary" role="alert">
                                    <strong>Ricorda:</strong> puoi anche assegnare la lavorazione ad un <strong>Team di lavoro</strong>, e ad un <strong>'team leader'</strong> o  <strong>'caposquadra'</strong> all'interno di questo.
                                  </b-alert>
                                </template>
                              </template>
                            </template>
                            <template v-else>
                              <template v-if="$route.name=='worksite-detail-task'">
                                  <b-alert show variant="danger" role="alert">
                                  <strong>Attenzione:</strong> E' indispensabile assegnare la lavorazione ad un <strong>Team di lavoro</strong>, e ad un <strong>'team leader'</strong> o  <strong>'caposquadra'</strong> all'interno di questo.
                                  </b-alert>
                                </template>
                                <template v-if="$route.name=='support-detail-task'">
                                  <b-alert show variant="primary" role="alert">
                                    <strong>Ricorda:</strong> puoi anche assegnare la lavorazione ad un <strong>Team di lavoro</strong>, e ad un <strong>'team leader'</strong> o  <strong>'caposquadra'</strong> all'interno di questo.
                                  </b-alert>
                                </template>
                            </template>
                        </template>
                      </div>
                    </div>   
                       
                  </div>     
                </div>
            </div>
            <div class="flex-shrink-0">
              Stato attività: 
              <div
                class="form-check form-switch form-switch-right form-switch-md"
               
              >
             
                <template v-if="data">
                  
                  <select class="form-select" v-model="data.status" @change="update">
                    <option value="init">Non ancora avviata</option>
                    <option value="pending">In attesa</option>
                    <option value="in progress">Lavorazione in corso</option>
                    <option value="completed">Completata</option>
                  </select>
                   
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="card">

          <!-- Card Body-->
          <div class="card-body" >
          
          <template v-if="data">
            
              <!-- Anagrafica cliente -->
              <div class="row mb-50 mt-20" v-if="project">
                 
                <div class="col-lg-12">
                 
                  <b-accordion class="custom-accordionwithicon-plus" id="accordionWithplusicon">
                      <template v-if="project.customerDetails">
                              <b-accordion-item :title="project.customerDetails.ragSoc" unvisible>
                              <div class="table-responsive">
                                  <table class="table mb-0">
                                      <thead>
                                          <tr>
                                              <th scope="col">Nominativo</th>
                                              <th scope="col">Indirizzo</th>
                                              <th scope="col">Città</th>
                                              <th scope="col">Telefono</th>
                                              <th scope="col">Email</th>
                                              <th scope="col">#</th>
                                          </tr>
                                      </thead>
                                      <tbody v-if="project">
                                          <tr>
                                              <td>{{ project.customerDetails.ragSoc }}</td>
                                              <td>{{ project.customerDetails.indirizzo }}</td>
                                              <td>{{ project.customerDetails.citta }}</td>
                                              <td>{{ project.customerDetails.telefono }}</td>
                                              <td><a :href="'mailto:'+project.customerDetails.email">{{ project.customerDetails.email }}</a></td>
                                              <td><button class="btn btn-info" title="Vedi anagrafica completa">Vedi</button></td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </b-accordion-item>
                      </template>
                      <b-accordion-item :title="labelLocation + worksite.address +' - '+worksite.city" unvisible>
                              <div class="row" v-if="worksite">
                                  <div class="col-lg-12">
                                  <div class="card-body mb-50">

                                      <div class="row mb-50 pl-10">
                                          <div class="col-md-12 col-lg-4">
                                          <label for="code-field" class="form-label"><strong>Indirizzo</strong></label>
                                              <div class="input-group mb-1">
                                                  {{ worksite.address }}
                                              </div>
                                          </div>
                                          <div class="col-md-12 col-lg-3">
                                          <label for="code-field" class="form-label"><strong>Città</strong></label>
                                          <div class="input-group mb-1">
                                              {{ worksite.city }}
                                          </div>
                                          </div>
                                          <div class="col-md-12 col-lg-2">
                                          <label for="code-field" class="form-label"><strong>C.A.P</strong></label>
                                          <div class="input-group mb-1" >
                                              {{ worksite.zipCode }}
                                          </div>
                                          </div>
                                      </div>
                                      <div class="row mb-50 mt-20 pl-10">
                                      <div class="col-md-12 col-lg-3">
                                          <label for="code-field" class="form-label"><strong>Inizio lavori</strong></label>
                                          <div class="input-group mb-1" v-if="worksite.startStr">
                                              <template v-if="worksite.startStr!=''">
                                                {{ convertDateToLocal(worksite.startStr) }}
                                              </template>
                                          </div>
                                      </div>
                                      <div class="col-md-12 col-lg-3">
                                          <label for="code-field" class="form-label"><strong>Fine lavori</strong></label>
                                          <div class="input-group mb-1" v-if="worksite.endStr">
                                              <template v-if=" worksite.endStr !=''">
                                                {{ convertDateToLocal( worksite.endStr ) }}
                                              </template>
                                          </div>
                                      </div>
                                      </div>
                                  
                                  </div>
                                  </div>
                              </div>
                        </b-accordion-item>
                      </b-accordion>
                </div>
                
              </div>
          <div class="mt-50">
              <div class="row background-btn-grey-1  mb-50 mt-50 pl-10  pt-10" >
                  <div class="col-md-12 col-lg-3 ">
                      <label for="code-field" class="form-label"><strong>Inizio attività</strong></label>
                          <div class="input-group mb-1">
                              <input type="datetime-local" class="form-control" :min="minDate" :max="maxDate" v-model="data.startStr" />
                          </div>
                      </div>
                      <div class="col-md-12 col-lg-3">
                          <label for="code-field" class="form-label"><strong>Fine attività</strong></label>
                              <div class="input-group mb-1">
                                  <input type="datetime-local" class="form-control"  :min="minDate" :max="maxDate"  v-model="data.endStr"/>
                              </div>
                      </div>
              </div>
              <div class="row background-btn-grey-1  pl-10 pt-10" >
                  <div class="col-md-12 col-lg-8">
                      <label for="code-field" class="form-label"><strong>Denominazione attività (opzionale)</strong></label>
                      <div class="input-group mb-1">
                          <input type="text" class="form-control" placeholder="Identifica la lavorazione" v-model="data.name"   />
                      </div>
                  </div>
              </div>
              <div class="row background-btn-grey-1 pl-10 pt-10 pb-20" >
                  <div class="col-md-12 col-lg-12">
                      <label for="code-field" class="form-label"><strong>Descrizione generale (opzionale)</strong></label>
                      <div class="input-group mb-1">
                          <textarea class="form-control" placeholder="Note generali lavorazione" v-model="data.details" ></textarea>
                      </div>
                  </div>
              </div>
              <div class="row  pl-10 pt-10 pb-20" >
                  <div class="col-md-12 col-lg-3">
                    <label for="code-field" class="form-label pt-2"><strong>Progresso lavorazione (%)</strong></label>
                  </div>
                  <div class="col-lg-1 col-md-12 text-left">
                  </div>
              </div>
              <div class="row  pl-10 pb-20" >
                  <div class="col-lg-1 col-md-12 text-left">
                    <input type="number" class="form-control" v-model="data.progress" min="1" max="100" @blur="updateProgress" />
                  </div>
              </div>
              <div class="row background-btn-grey-1 pl-10 pt-10 pb-20" >
                  <div class="col-md-12 col-lg-12">
                    <div
                    class="
                      progress
                      animated-progess
                      custom-progress
                      progress-label"
                      >
                        <div
                          class="progress-bar bg-info progress-xl"
                          role="progressbar"
                          :style="'width: '+data.progress+'%'"
                          :aria-valuenow="data.progress"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <div class="label">{{data.progress}}%</div>
                        </div>
                      </div>

                  </div>
              </div>
          </div>
      </template>
          <div class="row" v-if="data">
            <div class="col-md-12 col-lg-12 mt-50 border-btn-grey-2" v-if="data.status=='init'">
                  <b-alert show variant="danger" role="alert">
                    <strong>Attenzione:</strong> E' indispensabile modificare lo '<strong>Stato attività</strong>' per visualizzare i 'Rapportini'.
                  </b-alert>
            </div>
            <div class="col-md-12 col-lg-12  mt-50  mb-20 text-center" v-else>
              <template v-if="worksite">
                <createReport  type="worksite-task" :taskID="data._id"  :projectID="project._id" :pID="project._id" :worksiteID="worksite._id" :data="data"  @updateTeam="updateParent" />
              </template>
              
            </div> 
          </div>
        </div>
          <!-- / Card Body -->
        </div>
      </div>
     
      <div class="col-lg-4">
        <template v-if="project &&  worksite">
        <div class="card">
          <div class="card-header background-btn-grey-1">
            <h5 class="card-title mb-0">Team di lavoro</h5>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="choices-lead-input" class="form-label"
                >Assegna lavorazione:</label
              >
              <template v-if="worksite._id">
                <gestioneTeamDiLavoro type="worksite-task" :taskID="$route.params.id"  interventionID="" :projectID="project._id" :worksiteID="worksite._id" :data="data"  @updateTeam="updateParent" />
              </template>    
            </div>

          </div>
          </div>
          <div class="card">
            <div class="card-header background-btn-grey-1">
            <h5 class="card-title mb-0">Mezzi impegnati</h5>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="choices-lead-input" class="form-label"
                >Assegna Mezzo:</label
              >
              <template v-if="worksite._id">
                    <gestioneMezziCantiere type="worksite-task" :taskID="$route.params.id" interventionID="" :projectID="project._id" :worksiteID="worksite._id" :data="data"  @updateTeam="updateParent" />
                  </template>
            </div>

          </div>
        </div>
        </template>
      </div>
    
    </div>
  
  
</template>