<script>

  import useVuelidate from "@vuelidate/core";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  //import userSearchModelWS  from "@/components/widgets/worksites/userSearchModelWS";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'createReport',
    props: [
        'pID',
        'projectID',
        'worksiteID',
        'type',
        'taskID',
        'data',
    ],
    setup() {
      moment.locale('it')
      let tk = 'Bearer '+localStorage.getItem('tk')
      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Creazione rapporto di intervento",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        addTabPlan:false,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
            projectID:this.projectID,
            worksiteID:this.worksiteID,
            startStr:moment().format('YYYY-MM-DD'),
            endStr:"",
            name:"",
            details:"",
        },
        
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 10,
        sortRes:'asc',
        value: 'All',
        n_results:0,
        pages: [],
        responseList: [
        ],
        paginated:{},
        select:'all',
        search:'',
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        minDate:moment().format('YYYY-MM-DD'),
        maxDate:new Date().getFullYear().toString() + '-12-31',
        resultsList:[],
        teamLeaderID:'',
        qSearch:'',
        tID:this.taskID,
      };
    },
    components: {
   
    },
    validations: {
    },
    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      
    },

    methods: {
      convertDateTime(date){
        if (date){
          return  moment(date).format('DD/MM/YYYY HH:MM') 
        }
      },
      len(array){
        if(array){
          return array.length
        } else {
          return 0
        }
      },
      axiosInterceptor(){
        axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
      },
      async fullSearch(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}users/search/full/text`,
            { 
            params: { 
                q: this.qSearch,
            },
            headers: { 'authorization':this.tk} 
            }
        )
        .then((response) => {
            if (response.data)  {
            this.resultsList = response.data
            }
        })
      },
      getList(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}intervention-reports/by-taskid/${this.$route.params.id}`, 
          { 
            params: { 
              perPage: this.perPage,
              page: this.page,
              sortRes: this.sortRes,
              select: this.select,
              search: this.search,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          this.responseList = response.data.results.data;
          this.n_results = response.data.n_results;
          this.paginated = response.data.results
          })
      },
      SearchData(){
        this.searchQuery= this.filtersearchQuery1;
        this.filterdate = this.filterdate1;
        this.filtervalue = this.filtervalue1;
      },
      
      editdata(id,w,t) {
        this.$router.push('/tasks-reports/detail/'+ id +'/'+w+'/'+t)
      },
      addnew() {
        document.getElementById("addform").reset();
        document.getElementById('exampleModalLabel').innerHTML = this.$t('t-new-project');
        document.getElementById('add-btn').style.display = 'block';
       
      },
      initItem(){
        this.axiosInterceptor()
        this.submitted = true;
        this.v$.$touch();
        this.item.taskID = this.data._id
        this.item.worksiteID = this.data.worksiteID
        this.item.projectID = this.data.projectID

        this.item.type = 'tecnician-intervention'
        this.item.name = ''
        this.item.details = ''
        this.item.note = ''
        let today = moment().format('YYYY-MM-DD')
        this.item.dateTmp = moment(today).unix()
        this.item.year = moment().format('YYYY')
        this.item.month = today.split('-')[1]
        this.item.day =  today.split('-')[2]
        this.item.start = moment(today).valueOf()
        this.item.start_tmp = moment(today).unix()
        this.item.end = moment(today).valueOf()
        this.item.end_tmp = moment(today).unix()
        this.item.createdBy = this.datastore.auth.currentUser

        axios.post(`${this.UrlServer}intervention-reports/register`, this.item, {headers: { authorization:this.tk}} )
          .then((response)=>{
           
            if (response.data) {
              this.resultsList = []
              Swal.fire({  
                title: this.$t("t-msg-intrep-init") , 
                text: this.$t("t-msg-intrep-init-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              }).then(()=>{
                    
                    if (response.data){
                        this.$router.push('/tasks-reports/detail/'+response.data+'/'+this.worksiteID+'/'+this.taskID)
                    }
                  
              });
            }
        })
      },
      deletedata(id){
        let self = this
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}teams/by-worksite/${id}/${this.worksiteID}`, {headers: { authorization:this.tk}} )
                .then(()=>{
                  self.getList() 
                  this.$emit('updateTeam') 
              })
            }
        });
      },
      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(responseList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return responseList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      splitStr(str,car){
            if (str){
            if(str.includes(car)==true) {
                let val =  str.split(car)
                return `${val[0]} ${val[1]}`
            } else{
                return str
            }
            } else {
            return ''
            }
        },
        selectItem(params){
          let arrayUser = this.responseList.filter(function(el){
            return el.userID == params._id
          })
          if (arrayUser.length==0){
            this.initItem(params)
          } else {
            Swal.fire({
              title:"Utente già presente",
              text:"L'utente che hai selezionato è già presente nel Team!",
              icon:"info",
              showCancelButton:false,
              showConfirmButton:false,
              timer:3000,
            }) 
          }
        },
        updateTeamLeader(){
         
          let data = false
          if (this.teamLeaderID.length==24)
          {
            data = {
              taskID: this.data._id,
              worksiteID: this.worksiteID,
              status:'assigned',
            }
            this.axiosInterceptor()
            axios.put(`${this.UrlServer}teams/set-teamleader/${this.teamLeaderID}`, data, {headers: { authorization:this.tk}} )
            .then((response)=>{
              if (response.data) {
                this.$emit('updateTeam') 
              }
            })
          }

        },
    },
    mounted() {

      this.getList()
    }
  };
</script>

<template>
 <div>  
    <div class="row">
        <div class="col-lg-12 mb-50">
            <b-button pill  class="btn-soft-primary waves-effect waves-light" @click="initItem">
              <i class="mdi mdi-clipboard-edit-outline"></i>
                Scrivi rapporto d'intervento
            </b-button>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">            
            <div class="card-body">
              <div class="row thRow  ">
                <div class="col-lg-12 col-md-12 text-left">
                 Rapportini di intervento
                </div>
              </div>
            </div>
        </div>
    </div>
    <div  class="row">
        <div class="col-lg-12">
            <div class="card-header  mb-50">
              <div class="row align-items-center mb-50">
               
                <div class="col ">
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                        Tutti
                        <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_results}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">

                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
            <div class="table-card mb-1">
              <div class="row thRow mt-50">
                  <div class="col-lg-1 col-md-12">
                    Data inizio
                  </div>
                  <div class="col-lg-1 col-md-12">
                    Data fine
                  </div>
                  <div class="col-lg-3 col-md-12">
                    Incaricato
                  </div>
                  <div class="col-lg-2 col-md-12">
                    Stato lavorazione
                  </div>
                  <div class="col-lg-2 col-md-12">
                    Stato Invio
                  </div>
                  <div class="col-lg-2 col-md-12 text-left">
                      Visualizza
                  </div>
                </div>

                <div class="row list tRow form-check-all d-flex" v-for="(item, index) of responseList" :key="index">
                  <div class="col-lg-1 col-md-12 ">
                      <div class="list-inline hstack gap-2 mb-0" v-if="item">
                        {{ convertDateTime(item.startStr) }}
                      </div>
                  </div>
                  <div class="col-lg-1 col-md-12 ">
                      <div class="list-inline hstack gap-2 mb-0" v-if="item">
                        {{ convertDateTime(item.endStr) }} 
                      </div>
                  </div>
                  <div class="col-lg-3 col-md-12 ">
                      <div class="list-inline hstack gap-2 mb-0" v-if="item.task.length>0">
                        {{ item.task[0].assignedTo.firstName }} {{ item.task[0].assignedTo.lastName }} 
                      </div>
                  </div>
                  <div class="col-lg-2 col-md-12 text-center">
                      <div class="pl-50">
                        <template v-if="item">
                        
                          <template v-if="item.status=='init' || item.status==''">
                            Non Assegnato
                          </template>
                          <template v-else-if="item.status=='processing'">
                            <div class="avatar-sm flex-shrink-0">
                            <span class="avatar-title bg-info rounded-circle "  title="In lavorazione">
                              <i class="mdi mdi-timer-sand"></i>
                            </span>
                            </div>                              
                          </template>
                          <template v-else-if="item.status=='pending'">
                            <div class="avatar-sm flex-shrink-0">
                            <span class="avatar-title bg-warning rounded-circle "  title="In attesa">
                              <i class="bx bx-time-five"></i>
                            </span>
                            </div>
                          </template>   
                          <template v-else-if="item.status=='problem'">
                            <div class="avatar-sm flex-shrink-0">
                              <span class="avatar-title bg-danger rounded-circle" title="Problematica">
                                <i class="bx bx-alarm-exclamation"></i>
                            </span>
                            </div>
                          </template>                         
                          <template v-else-if="item.status=='completed'">
                            <div class="avatar-sm flex-shrink-0">
                              <span class="avatar-title bg-success rounded-circle"  title="Completa">
                                <i class="ri-checkbox-circle-line"></i>
                            </span>
                            </div>
                          </template>
                          <template v-else>
                            <div class="avatar-sm flex-shrink-0">
                              <span class="avatar-title bg-info rounded-circle "  title="In lavorazione">
                              <i class="mdi mdi-timer-sand"></i>
                            </span>
                            </div>
                          </template>    

                        </template>
                        <template v-else>
                          N.D.
                        </template>
                      </div>
                  </div>
                  <div class="col-lg-2 col-md-12 text-left">
                    <div class="pl-50">
                      <template v-if="item.invioDocumento">
                      <div class="avatar-sm flex-shrink-0">
                        <span class="avatar-title bg-success rounded-circle"  title="Inviati al coordinatore">
                          <i class="bx bx-send"></i>
                        </span>
                      </div>
                    </template>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12 text-left">
                      <ul class="list-inline ">
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Modifica" v-if="item">
                            <template v-if="item">
                              <a href="javascript:void(0)" class="text-primary d-inline-block edit-item-btn" @click="editdata(item._id,worksiteID,item.taskID)">
                                <i class="ri-pencil-fill fs-16"></i>
                              </a>
                            </template>
                          </li>
                      </ul>
                  </div>
                </div>
          </div>
            <div>
              <div class="table-responsive table-card mb-1">

                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                      :height="75" :width="75" />
                    <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                    <p class="text-muted mb-0">
                     
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex tRow justify-content-end mt-3" v-if="n_results > perPage">        
                    <div class="col-lg-3 col-md-12 ">
                        <label class="pt-2">Risultati per pagina:</label>
                        <div class="pl-10 pr-20">
                        
                        <select class="form-control" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                        <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }" v-for="index in paginated.total_pages" :key="index">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                        
                        </ul>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
              </div>
            </div>
            </div>
        </div>
    </div>
</div> 
</template>